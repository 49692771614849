import React from 'react'
import styled from '@emotion/styled'

// -------------------------------------------------
// Topで使用するアクセントの／線
// -------------------------------------------------

const SlashBorder = styled.div`
  position: absolute;
  bottom: -4em;
  right: 0;
  font-size: 0.8em;
  font-weight: 600;
  &:before {
    position: absolute;
    bottom: 1.5em;
    right: 2em;
    height: 1px;
    width: 48px;
    background: #121212;
    display: block;
    content: '';
    transform: rotate(-51.6deg);
  }
`

const More = props => {
  return <SlashBorder>More →</SlashBorder>
}

export default More
