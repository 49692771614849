import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Container from '../components/Container'
import SEO from '../components/SEO'
import TopActCard from "../components/TopActCard";
import PageTitle from "../components/PageTitle"
import Slash from '../components/Slash/Slash'
import ItemWrapper from '../components/Item/ItemWrapper'
import TopSpaceCard from "../components/TopSpaceCard";
import SimpleSlider from '../components/Slider'
import Img from 'gatsby-image'
import Close from '../components/Close'
import { startCase } from 'lodash'

const Top = ({ data, pageContext }) => {
  const currents = data.allContentfulActivity.edges.filter(activity => activity.node.status == "current")
  const upcomings = data.allContentfulActivity.edges.filter(activity => activity.node.status == "upcoming")
  const archives = data.allContentfulActivity.edges.filter(activity => activity.node.status == "archive").slice(0, 2)
  const about = data.contentfulSpace
  const { basePath, status } = pageContext

  let ogImage

  try {
    ogImage = currents[0].node.thumbnail.file.url ? currents[0].node.thumbnail.file.url : upcomings[0].node.thumbnail.file.url
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout upcomingsLength={upcomings.length}>
      <SEO image={ogImage} />
      <Container>
        {currents.length == 0 && upcomings.length == 0 && (
          <ItemWrapper>
            <SimpleSlider marginBottom={"8em"}>
              {
                archives.map(archive =>
                  <Link to={`/${archive.node.status}/${archive.node.slug}/`}>
                    <Img
                      fluid={archive.node.thumbnail.fluid}
                      alt={archive.node.thumbnail.title}
                      backgroundColor={'#eeeeee'}
                    />
                  </Link>
                )
              }
            </SimpleSlider>
          </ItemWrapper>
        )}
        {(currents.length >= 1) ? (
          <>
            <PageTitle>current</PageTitle>
            <Slash />
            <ItemWrapper>
              <CardList>
                {
                  currents.map(({ node: current }) => (
                    <TopActCard key={current.id} {...current} basePath={basePath} />
                  ))
                }
              </CardList>
            </ItemWrapper>
          </>
        ) : (
          <>
            {(upcomings.length >= 1) ? (
              ""
            ) : (
              <>
                <PageTitle margin="6em 0 0 0.25em">current</PageTitle>
                <Slash />
                <ItemWrapper>
                  <Close />
                </ItemWrapper>
              </>
            )}
          </>
        )}
        {(upcomings.length >= 1) ? (
          <>
            {(currents.length >= 1) ? (
              <PageTitle margin="6em 0 0 0.25em">upcoming</PageTitle>
            ) : (
              <PageTitle>upcoming</PageTitle>
            )}
            <Slash />
            <ItemWrapper>
              <CardList>
                {
                  upcomings.map(({ node: upcoming }) => (
                    <TopActCard key={upcoming.id} {...upcoming} basePath={basePath} />
                  ))
                }
              </CardList>
            </ItemWrapper>
          </>
        ) : ('')}
        <PageTitle margin="6em 0 0 0.25em">about</PageTitle>
        <Slash />
        <ItemWrapper>
          <CardList>
            <TopSpaceCard key={about.id} {...about} basePath={basePath} />
          </CardList>
        </ItemWrapper>
      </Container>
    </Layout >
  )
}

export const query = graphql`
  query {
    allContentfulActivity(
     sort: {fields: startDate, order: DESC}
    ) {
      edges {
        node {
          slug
          id
          title
          thumbnail {
            title
            fluid(maxWidth: 800, maxHeight: 566, resizingBehavior: PAD) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            file {
              url
            }
          }
          status
          introduction {
            json
          }
          startDate
          endDate
        }
      }
    }
    contentfulSpace(slug: { eq: "about" }) {
      id
      slug
      image {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      name
      description {
        json
      }
    }
  }
`

export default Top
