import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import More from './More'
import Information from '../components/Item/Information'

const Activity = styled.li`
  position: relative;
  margin: 6em 0 4em 0;
  width: 100%;
  transition: background 0.2s;
  max-width: 528px;
  &:first-child {
    margin: 0 0 4em 0;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.text};
    .gatsby-image-wrapper {
      max-height: 373.352px;
    }
  }
  &:hover {
    background: ${props => props.theme.colors.tertiary};
  }
  p {
    display: block;
    padding-bottom: 2em;
    border-bottom: 1px solid #121212;
  }
`

const Title = styled.h2`
  font-size: 1.3em;
  font-weight: 400;
  margin: 1em 1rem 1em 0;
`

const TopActCard = ({ slug, thumbnail, title, status, introduction, startDate, endDate, ...props }) => {
  return (
    <>
      {thumbnail && (
        <Activity>
          <Link to={`/${status}/${slug}/`}>
            <Img fluid={thumbnail.fluid} backgroundColor={'#eeeeee'} />
            <Title>{title}</Title>
            <Information
              startDate={startDate ? startDate : null}
              endDate={endDate ? endDate : null}
              status={status}
            />
            <More />
          </Link>
        </Activity>
      )}
    </>
  )
}

export default TopActCard
