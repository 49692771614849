import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import More from './More'
import TopBody from "../components/TopBody";
import Body from '../components/Item/Body'



const Activity = styled.li`
  position: relative;
  margin: 0 0 4em 0;
  width: 100%;
  transition: background 0.2s;
  max-width: 528px;
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.text};
    .gatsby-image-wrapper {
      max-height: 373.352px;
    }
  }
  &:hover {
    background: ${props => props.theme.colors.tertiary};
  }
`

const Title = styled.h2`
  font-size: 1em;
  font-weight: 400;
  margin: 1rem 1rem 1rem 0;
`

const MoreWrapper = styled.div`
  max-height: 150px;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    background: linear-gradient(180deg,rgba(255, 255, 255, 0),15%,#ffffff);
    width: 100%;
    height: 68%;
    position: absolute;
    bottom: 0;
  }
`

const TopSpaceCard = ({ slug, image, name, description, ...props }) => {
  return (
    <>
      {image && (
        <Activity>
          <Link to={`/${slug}/`}>
            <Img fluid={image[0].fluid} backgroundColor={'#eeeeee'} />
            <MoreWrapper>
              <Body>
                {documentToReactComponents(description.json)}
              </Body>
            </MoreWrapper>
            <More />
          </Link>
        </Activity>
      )}
    </>
  )
}

export default TopSpaceCard
