import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`

const CloseImg = styled.div`
  font-size: 2em;
  font-weight: 600;
  margin: 0.5em 0;
`

const Close = () => {
  return (
    <>
      <Wrapper>
        <CloseImg>
          Closed
        </CloseImg>
      </Wrapper>
      <p style={{
        borederBottom: "1px solid #121212",
        display: "block",
        width: "100%"
      }}>開催中の企画はありません。</p>
    </>
  )
}

export default Close
